import React, { useState } from 'react';
import servicesData from '../data/servicesData';
import './Services.css';

const Services = () => {
  const [selectedService, setSelectedService] = useState(null); // State for selected service
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  const openModal = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedService(null);
    setIsModalOpen(false);
  };

  const renderServicesByCategory = (category) => {
    return servicesData
      .filter((service) => service.category === category)
      .map((service) => {
        const originalPrice = service.savings
          ? service.price + service.savings
          : service.price;

        return (
          <div key={service.id} className="service-card">
            <img
              src={service.image}
              alt={`${service.title} - service`}
              className="service-image"
              loading="lazy"
            />
            <h3>{service.title}</h3>
            <p className="service-description">{service.description}</p>
            <p className="service-price">
              {service.savings && (
                <span className="original-price">${originalPrice}</span>
              )}
              ${service.price}
            </p>
            {service.savings && (
              <p className="service-savings">Save ${service.savings}!</p>
            )}
            <div className="service-actions services-button-container">
              {/* "More Info" Button */}
              <button
                className="info-button services-info-button"
                onClick={() => openModal(service)}
              >
                More Info
              </button>
              {/* Redirect to the Appointments Page */}
              <button
                className="cta-button services-cta-button"
                onClick={() => (window.location.href = `https://gloss-wise-detailing.square.site/s/appointments`)}
              >
                Book Now
              </button>
            </div>
          </div>
        );
      });
  };

  return (
    <>
      <section className="services-section">
        <h2>Interior Detailing Packages</h2>
        <div className="services-grid">{renderServicesByCategory('Interior')}</div>
        <h2>Exterior Detailing Packages</h2>
        <div className="services-grid">{renderServicesByCategory('Exterior')}</div>
        <h2>Combo Packages</h2>
        <div className="services-grid">{renderServicesByCategory('Combo')}</div>
      </section>

      {/* Modal */}
      {isModalOpen && selectedService && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeModal}>
              &times;
            </button>
            <h3>{selectedService.title}</h3>
            <p>{selectedService.description}</p>
            <div>
              <h4>Benefits:</h4>
              <ul>
                {selectedService.details.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
            </div>
            <div>
              <h4>What’s Included:</h4>
              <ul>
                {selectedService.details.included.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Services;
