import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './About.css';

const About = () => {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>About Gloss Wise - Your Car Detailing Experts in Coffs Harbour</title>
        <link rel="canonical" href="https://glosswise.com.au/about" />
        <meta
          name="description"
          content="Learn more about Gloss Wise, your trusted car detailing and ceramic coating experts in Coffs Harbour, NSW."
        />
      </Helmet>
      <div className="about-page" role="main" aria-labelledby="about-page-title">
        {/* Hero Section */}
        <section className="about-hero" aria-labelledby="about-hero-title">
          <div className="about-hero-content">
            <h1 id="about-hero-title">Welcome to Gloss Wise Detailing</h1>
            <p>Your trusted partner in premium car detailing and protection services.</p>
          </div>
        </section>

        {/* Mission and Values */}
        <section className="about-mission" aria-labelledby="mission-title">
          <h2 id="mission-title">Our Mission</h2>
          <p>
            At Gloss Wise, we are dedicated to providing top-tier car detailing services that keep your vehicle looking
            pristine and protected. We believe in using eco-friendly, high-quality products for long-lasting results.
          </p>
          <h2 id="values-title" className="values-heading">Our Values</h2>
          <ul className="values-list" aria-labelledby="values-title">
            <li>Quality First</li>
            <li>Honesty & Transparency</li>
            <li>Commitment to Excellence</li>
            <li>Customer Satisfaction</li>
          </ul>
        </section>

        {/* Our Story */}
        <section className="about-story" aria-labelledby="story-title">
          <h2 id="story-title">Our Story</h2>
          <p>
            Gloss Wise started from a passion for cars and a drive to deliver unparalleled detailing services. Today, we
            serve countless satisfied customers with our expert knowledge and attention to detail.
          </p>
        </section>

        {/* Why Choose Us */}
        <section className="why-choose-us" aria-labelledby="why-choose-title">
          <h2 id="why-choose-title">Why Choose Gloss Wise?</h2>
          <div className="choose-us-cards">
            <div className="choose-card" role="region" aria-labelledby="expertise-title">
              <h3 id="expertise-title">Expertise You Can Trust</h3>
              <p>Years of experience in detailing and paint protection.</p>
            </div>
            <div className="choose-card" role="region" aria-labelledby="satisfaction-title">
              <h3 id="satisfaction-title">100% Satisfaction Guarantee</h3>
              <p>We guarantee you’ll be satisfied, or we’ll make it right.</p>
            </div>
            <div className="choose-card" role="region" aria-labelledby="eco-friendly-title">
              <h3 id="eco-friendly-title">Eco-Friendly Products</h3>
              <p>We use only the best eco-friendly products for our services.</p>
            </div>
          </div>
        </section>

        {/* High-Quality Equipment and Chemicals */}
        <section className="quality-equipment" aria-labelledby="quality-title">
          <h2 id="quality-title">High-Quality Equipment and Chemicals</h2>
          <p>
            We use only the highest quality equipment and chemicals to ensure the best results for our clients. Our
            ceramic paint protection includes a 2-layer coating using GTECHNIQ with a 5-year guarantee and a 2-year
            hydrophobic top layer. This helps prevent dust and dirt build-up, promoting a self-cleaning effect for your
            car.
          </p>

          {/* Embedded Video */}
          <div className="video-container">
            <iframe
              width="1280"
              height="720"
              src="https://www.youtube.com/embed/H55WBUagx_c"
              title="Gtechniq Crystal Serum Light Showcase"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
        </section>

        {/* Call to Action */}
        <section className="about-cta" aria-labelledby="cta-title">
          <h2 id="cta-title">Ready to Give Your Car the Care It Deserves?</h2>
          <button
            className="cta-button"
            onClick={() => (window.location.href = 'https://gloss-wise-detailing.square.site/s/appointments')}
            aria-label="Book your car detailing service now"
          >
            Book Your Detailing Now
          </button>
        </section>
      </div>
    </>
  );
};

export default About;
