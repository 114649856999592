// src/components/Header.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import logo from '../assets/images/logo.png';
import logoText from '../assets/images/logoText.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      {/* Full-width Call Us Banner */}
      <div className="call-banner">
        <a href="tel:0409756913" className="call-link" aria-label="Call Gloss Wise Detailing on 0409756913">
          Call Us: 0409 756 913
        </a>
      </div>

      {/* Main Logo and Navigation */}
      <div className="header-content">
        <div className="logo-container">
          <img
            src={logo}
            alt="Gloss Wise Logo"
            className="logo"
            width="100"
            height="100"
          />
          <img
            src={logoText}
            alt="Gloss Wise Text"
            className="logo-text"
            width="200"
            height="50"
          />
        </div>

        <button
          className="hamburger"
          onClick={toggleMenu}
          aria-label={menuOpen ? 'Close menu' : 'Open menu'}
          aria-expanded={menuOpen}
        >
          <i className={menuOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
        </button>
      </div>

      <nav className={`navbar ${menuOpen ? 'open' : ''}`} aria-label="Main Navigation">
        <ul>
          <li>
            <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')} onClick={() => setMenuOpen(false)}>
              Services
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" className={({ isActive }) => (isActive ? 'active' : '')} onClick={() => setMenuOpen(false)}>
              About
            </NavLink>
          </li>
          <li>
            <a
              href="https://gloss-wise-detailing.square.site/s/appointments"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
              onClick={() => setMenuOpen(false)}
            >
              Book
            </a>
          </li>
          <li>
            <NavLink to="/contact" className={({ isActive }) => (isActive ? 'active' : '')} onClick={() => setMenuOpen(false)}>
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
