import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import Hero from '../components/Hero';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials';
import ServiceAreas from '../components/ServiceAreas';

// Lazy load the Popup component
const Popup = React.lazy(() => import('../components/Popup'));

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Gloss Wise - Car Detailing & Ceramic Coating in Coffs Harbour, NSW</title>
        <link rel="canonical" href="https://glosswise.com.au/" />
        <meta
          name="description"
          content="Gloss Wise offers premium car detailing and ceramic coating services in Coffs Harbour, NSW. Enhance your car's appearance today!"
        />
      </Helmet>
      <Hero />
      <Services />
      <Testimonials />
      <ServiceAreas />
      {/* Lazy-loaded Popup */}
      <Suspense fallback={null}>
        <Popup />
      </Suspense>
    </>
  );
};

export default Home;
