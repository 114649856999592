import React from 'react';
import './Hero.css'; // Ensure CSS styling is correctly linked
import heroImage from '../assets/images/hero.webp';

const Hero = () => {
  const handleBookingClick = () => {
    window.location.href = 'https://gloss-wise-detailing.square.site/s/appointments'; // Redirect to Square Appointments
  };

  return (
    <section className="hero-section" aria-labelledby="hero-heading">
      <img
        src={heroImage}
        alt="A beautifully detailed car in showroom condition"
        className="hero-background"
        width="100%"
        height="100%"
        aria-hidden="true"
      />
      <div className="hero-overlay" aria-hidden="true"></div>
      <div className="hero-content">
        <h1 id="hero-heading">Experience the Ultimate Car Detailing Service</h1>
        <p>Discover premium detailing with fixed pricing—no surprises, just a showroom-quality finish every time.</p>
        <button
          onClick={handleBookingClick}
          className="cta-button"
          aria-label="Book your car detailing appointment now"
        >
          Book Your Detailing Now
        </button>
      </div>
    </section>
  );
};

export default Hero;
