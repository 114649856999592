import React from 'react';
import { Helmet } from 'react-helmet-async';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Gloss Wise</title>
        <link rel="canonical" href="https://glosswise.com.au/privacy-policy" />
        <meta name="description" content="Learn how Gloss Wise protects your privacy and handles your personal information when you use our services." />
      </Helmet>
      <div className="privacy-policy-wrapper">
        <div className="privacy-policy-container" role="document" aria-labelledby="privacy-policy-title">
          <h1 id="privacy-policy-title">Privacy Policy</h1>
          <p>
            <strong>Gloss Wise</strong> is committed to protecting your personal information and respecting your privacy.
          </p>
          <p>Effective Date: January 1, 2024</p>

          <h2 id="information-we-collect">1. Information We Collect</h2>
          <ul aria-labelledby="information-we-collect">
            <li>Contact details: name, email address, phone number, and address.</li>
            <li>Vehicle details: make, model, and condition.</li>
            <li>Booking details and additional information you provide.</li>
          </ul>

          <h2 id="how-we-use-your-information">2. How We Use Your Information</h2>
          <ul aria-labelledby="how-we-use-your-information">
            <li>To provide, maintain, and improve our services.</li>
            <li>To process transactions and send related information.</li>
            <li>To communicate with you about bookings, services, and promotions.</li>
          </ul>

          <h2 id="security">3. Security</h2>
          <p>We implement measures such as encryption and secure payment gateways to protect your data. However, no system is entirely secure.</p>

          <h2 id="changes-to-policy">4. Changes to This Policy</h2>
          <p>We may update this Privacy Policy periodically. Changes will be posted on this page.</p>

          <h2 id="contact-us">5. Contact Us</h2>
          <p>Questions? Contact us at <strong>zackary@glosswise.com.au</strong>.</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
