import React, { useEffect, useState } from 'react';
import './Testimonials.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import ccR1 from '../assets/images/ccR1.webp';
import ccR2 from '../assets/images/ccR2.webp';
import ccR3 from '../assets/images/ccR3.webp';
import lpR1 from '../assets/images/lpR1.webp';
import lpR2 from '../assets/images/lpR2.webp';
import lpR3 from '../assets/images/lpR3.webp';
import lpR4 from '../assets/images/lpR4.webp';

const testimonialsData = [
  {
    id: 1,
    name: 'Cherryl Cowled',
    review: 'This service was excellent, my car interior looks better now, than when I bought it second hand at a dealership 7 years ago.',
    rating: 5,
    images: [ccR1, ccR2, ccR3],
  },
  {
    id: 2,
    name: 'Laurie Preston',
    review: 'Absolutely blown away by the transformation on my i20! It was covered in pet hair and sand, but after a full interior detail from Gloss Wise, it looks brand new again.',
    rating: 5,
    images: [lpR1, lpR2, lpR3, lpR4],
  },
];

const Testimonials = () => {
  return (
    <section className="testimonials-section" aria-labelledby="testimonials-heading">
      <h2 id="testimonials-heading">What Our Clients Are Saying</h2>
      <div className="testimonials-grid" role="list">
        {testimonialsData.map((testimonial) => (
          <ReviewCard key={testimonial.id} testimonial={testimonial} />
        ))}
      </div>
      <a
        href="https://www.google.com/maps/place/?q=place_id:ChIJtXQIFour8EERt05Fxtcf8A8"
        target="_blank"
        rel="noopener noreferrer"
        className="read-more-button"
      >
        Read More Reviews on Google
      </a>
    </section>
  );
};

const ReviewCard = ({ testimonial }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        (prevIndex + 1) % testimonial.images.length
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [testimonial.images.length]);

  return (
    <div className="testimonial-card" role="listitem" aria-label={`Testimonial from ${testimonial.name}`} tabIndex={0}>
      <div className="carousel">
        {testimonial.images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Review from ${testimonial.name}`}
            className={`testimonial-image ${index === currentImageIndex ? 'active' : ''}`}
            loading="lazy"
          />
        ))}
      </div>
      <div className="testimonial-content">
        <p className="testimonial-review">{testimonial.review}</p>
        <div className="testimonial-rating">
          {Array.from({ length: testimonial.rating }, (_, i) => (
            <FontAwesomeIcon
              key={i}
              icon={faStar}
              className="star-icon"
              aria-hidden="true"
            />
          ))}
          {/* Visually hidden text for screen readers */}
          <span className="visually-hidden">{`Rating: ${testimonial.rating} out of 5 stars`}</span>
        </div>
        <h4 className="testimonial-name">{testimonial.name}</h4>
      </div>
    </div>
  );
};

export default Testimonials;
