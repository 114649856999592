import expressInteriorDetail from '../assets/images/expressInteriorDetail.webp';
import fullInteriorDetail from '../assets/images/fullInteriorDetail.webp';
import premiumInteriorDetail from '../assets/images/premiumInteriorDetail.webp';

import expressExteriorDetail from '../assets/images/expressExteriorDetail.webp';
import fullExteriorDetail from '../assets/images/fullExteriorDetail.webp';
import premiumExteriorDetail from '../assets/images/premiumExteriorDetail.webp';

import expressComboDetail from '../assets/images/expressComboDetail.webp';
import fullComboDetail from '../assets/images/fullComboDetail.webp';
import premiumComboDetail from '../assets/images/premiumComboDetail.webp';

const servicesData = [
  {
    id: 1,
    title: 'Express Interior Detail',
    category: 'Interior',
    description: 'A quick and affordable service designed to freshen up your car’s interior, perfect for maintaining cleanliness on the go.',
    price: 135,
    image: expressInteriorDetail,
    details: {
      benefits: [
        'Keeps your car looking tidy and professional.',
        'Affordable option for regular maintenance.',
        'Fast turnaround time.',
      ],
      included: [
        'Full vacuum of carpets, mats, and seats.',
        'Wiping down all interior surfaces (dashboard, doors, center console).',
        'Interior window cleaning for clear visibility.',
      ],
    },
  },
  {
    id: 2,
    title: 'Full Interior Detail',
    category: 'Interior',
    description: 'A deep interior cleaning service that restores your car to like-new condition, tackling dirt, stains, and odors.',
    price: 300,
    image: fullInteriorDetail,
    details: {
      benefits: [
        'Restores your interior to like-new condition.',
        'Eliminates odors and tough stains.',
        'Perfect for families, pet owners, or anyone selling their car.',
      ],
      included: [
        'Thorough vacuuming of all areas.',
        'Steam cleaning for carpets and upholstery.',
        'Deep stain removal and fabric reconditioning.',
        'Complete dashboard, door panel, and console cleaning.',
        'Interior window cleaning for spotless clarity.',
      ],
    },
  },
  {
    id: 3,
    title: 'Premium Interior Detail',
    category: 'Interior',
    description: 'Our top-tier interior service for those who demand excellence. Includes premium treatments to protect and preserve your interior.',
    price: 500,
    image: premiumInteriorDetail,
    details: {
      benefits: [
        'Adds long-term protection to your interior.',
        'Leaves a luxurious finish and a lasting fresh scent.',
        'Ideal for luxury vehicles and those seeking the best care.',
      ],
      included: [
        'Everything from the Full Interior Detail.',
        'Deodorizing treatment to neutralize odors.',
        'Fabric protection for seats and carpets.',
        'Leather cleaning and conditioning for long-lasting softness and durability.',
      ],
    },
  },
  {
    id: 4,
    title: 'Express Exterior Detail',
    category: 'Exterior',
    description: 'An efficient exterior service to keep your car clean and protected against the elements.',
    price: 150,
    image: expressExteriorDetail,
    details: {
      benefits: [
        'Affordable option for regular exterior maintenance.',
        'Adds a temporary protective layer.',
        'Quick service to maintain your car’s appearance.',
      ],
      included: [
        'Pre-wash and gentle hand wash.',
        'Exterior windows cleaned to a streak-free finish.',
        'Tire cleaning and dressing for a polished look.',
        'Application of SiO2 topper for temporary hydrophobic protection.',
      ],
    },
  },
  {
    id: 5,
    title: 'Full Exterior Detail',
    category: 'Exterior',
    description: 'A comprehensive exterior service that restores shine and provides lasting protection for your vehicle’s paintwork.',
    price: 350,
    image: fullExteriorDetail,
    details: {
      benefits: [
        'Restores your vehicle’s showroom shine.',
        'Protects against environmental contaminants.',
        'Includes a durable 6-month protective coating.',
      ],
      included: [
        'Thorough pre-wash and hand wash.',
        'Clay bar treatment to remove embedded contaminants.',
        '1-step paint correction to enhance shine and remove light imperfections.',
        'Application of a 6-month hydrophobic protective coating.',
      ],
    },
  },
  {
    id: 6,
    title: 'Premium Exterior Detail',
    category: 'Exterior',
    description: 'The ultimate exterior treatment with GTECHNIQ 5-year ceramic coating for unparalleled shine and protection.',
    price: 700,
    image: premiumExteriorDetail,
    details: {
      benefits: [
        'Industry-leading ceramic coating for maximum durability.',
        'Prevents dust, dirt, and grime from sticking to your car.',
        'Long-lasting protection for up to 5 years.',
      ],
      included: [
        'All steps from the Full Exterior Detail.',
        'GTECHNIQ 2-layer ceramic coating for unmatched durability.',
        '2-year hydrophobic top layer for effortless cleaning.',
        'Enhanced UV protection to prevent paint fading.',
      ],
    },
  },
  {
    id: 7,
    title: 'Express Combo Detail',
    category: 'Combo',
    description: 'A quick and efficient combination of interior and exterior detailing at a discounted rate.',
    price: 250,
    savings: 35,
    image: expressComboDetail,
    details: {
      benefits: [
        'Affordable package for regular maintenance.',
        'Cleans both interior and exterior for a polished look.',
        'Fast and efficient service.',
      ],
      included: [
        'All steps from the Express Interior Detail.',
        'All steps from the Express Exterior Detail.',
      ],
    },
  },
  {
    id: 8,
    title: 'Full Combo Detail',
    category: 'Combo',
    description: 'A comprehensive interior and exterior detailing service that restores your car to its original glory.',
    price: 600,
    savings: 50,
    image: fullComboDetail,
    details: {
      benefits: [
        'Perfect for those who want the whole package.',
        'Restores both interior and exterior to pristine condition.',
        'Includes stain removal and paint correction for a complete refresh.',
      ],
      included: [
        'All steps from the Full Interior Detail.',
        'All steps from the Full Exterior Detail.',
      ],
    },
  },
  {
    id: 9,
    title: 'Premium Combo Detail',
    category: 'Combo',
    description: 'The ultimate detailing package combining all premium interior and exterior services.',
    price: 1100,
    savings: 100,
    image: premiumComboDetail,
    details: {
      benefits: [
        'Ideal for luxury vehicles or special occasions.',
        'Provides maximum protection and lasting beauty.',
        'Combines top-tier interior and exterior treatments.',
      ],
      included: [
        'All steps from the Premium Interior Detail.',
        'All steps from the Premium Exterior Detail.',
      ],
    },
  },
];

export default servicesData;
