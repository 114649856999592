import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import './Contact.css';

const Contact = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch('https://glosswise.com.au/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setFormData({ name: '', email: '', phone: '', message: '' });
      navigate('/thank-you');
    } else {
      alert('There was an issue sending your message. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <Helmet>
        <title>Contact Gloss Wise - Book Your Car Detailing Appointment Today</title>
        <link rel="canonical" href="https://glosswise.com.au/contact" />
        <meta name="description" content="Get in touch with Gloss Wise for premium car detailing and ceramic coating services in Coffs Harbour, NSW." />
      </Helmet>
      <div className="contact-page" role="main" aria-labelledby="contact-page-title">
        {/* Hero Section */}
        <section className="contact-hero" aria-labelledby="contact-hero-title">
          <div className="contact-hero-content">
            <h1 id="contact-hero-title">Contact Gloss Wise</h1>
            <p>We're here to answer your questions and help you book your next detailing service.</p>
          </div>
        </section>

        {/* Contact Form Section */}
        <section className="contact-form-section" aria-labelledby="contact-form-title">
          <h2 id="contact-form-title">Get in Touch</h2>
          <p>Have a question or want to book a service? Fill out the form below, and we’ll get back to you promptly!</p>
          <form onSubmit={handleSubmit} aria-describedby="form-description">
            <div id="form-description" className="sr-only">
              All fields are required. Please provide your full name, email address, phone number, and message.
            </div>
            <div className="form-group">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your full name"
                value={formData.name}
                onChange={handleChange}
                required
                maxLength="50"
                aria-required="true"
                aria-label="Full Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                required
                maxLength="100"
                aria-required="true"
                aria-label="Email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Enter your phone number"
                value={formData.phone}
                onChange={handleChange}
                required
                maxLength="15"
                pattern="^\+?[0-9\s\-]{7,15}$"
                title="Please enter a valid phone number"
                aria-required="true"
                aria-label="Phone Number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="How can we help you?"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
                maxLength="1000"
                aria-required="true"
                aria-label="Message"
              ></textarea>
            </div>
            <button type="submit" className="cta-button" aria-label="Send your message">Send Message</button>
          </form>
        </section>

        {/* Contact Details Section */}
        <section className="contact-details" aria-labelledby="contact-info-title">
          <h2 id="contact-info-title">Contact Information</h2>
          <div className="details-container">
            <div className="detail" role="region" aria-labelledby="email-us-title">
              <h3 id="email-us-title">Email Us</h3>
              <p>
                <a href="mailto:zackary@glosswise.com.au" aria-label="Send an email to zackary@glosswise.com.au">
                  zackary@glosswise.com.au
                </a>
              </p>
            </div>
            <div className="detail" role="region" aria-labelledby="call-us-title">
              <h3 id="call-us-title">Call Us</h3>
              <p>
                <a href="tel:+61409756913" aria-label="Call Gloss Wise at +61 409 756 913">
                  +61 409 756 913
                </a>
              </p>
            </div>
            <div className="detail" role="region" aria-labelledby="visit-us-title">
              <h3 id="visit-us-title">Visit Us</h3>
              <p>
                <a
                  href="https://www.google.com/maps/place/?q=place_id:ChIJtXQIFour8EERt05Fxtcf8A8"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Open Gloss Wise business profile in Google Maps"
                >
                  4 Ashmore Close, Boambee East, NSW
                </a>
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;
