import React from 'react';
import { Helmet } from 'react-helmet-async';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions - Gloss Wise</title>
        <link rel="canonical" href="https://glosswise.com.au/terms-and-conditions" />
        <meta name="description" content="Review the terms and conditions for using Gloss Wise's car detailing and ceramic coating services." />
      </Helmet>
      <div className="terms-and-conditions-wrapper">
        <div className="terms-and-conditions-container">
          <a href="#terms-content" className="skip-link">Skip to Content</a>
          <main id="terms-content" role="main">
            <h1>Terms and Conditions</h1>
            <p>Effective Date: January 1, 2024</p>

            <h2>Table of Contents</h2>
            <ul>
              <li><a href="#introduction">1. Introduction</a></li>
              <li><a href="#services-provided">2. Services Provided</a></li>
              <li><a href="#payments">3. Payments</a></li>
              <li><a href="#liability">4. Liability</a></li>
              <li><a href="#customer-obligations">5. Customer Obligations</a></li>
              <li><a href="#changes-to-terms">6. Changes to Terms</a></li>
              <li><a href="#governing-law">7. Governing Law</a></li>
              <li><a href="#contact-us">8. Contact Us</a></li>
            </ul>

            <h2 id="introduction">1. Introduction</h2>
            <p>Welcome to <strong>Gloss Wise</strong>. By booking our car detailing services, you agree to be bound by the following terms and conditions. Please read them carefully.</p>

            <h2 id="services-provided">2. Services Provided</h2>
            <p>Gloss Wise offers mobile and drop-off car detailing services in New South Wales, Australia. Services are described in detail on our website, and we strive to ensure the information is accurate and up-to-date.</p>

            <h2 id="payments">3. Payments</h2>
            <p>Payments must be made in full before or upon completion of services. We accept cash, credit cards, and other methods as specified on our website. All prices include GST where applicable. Failure to pay may result in additional charges or refusal of service.</p>

            <h2 id="liability">4. Liability</h2>
            <p>Gloss Wise will exercise all due care when performing car detailing services. However, we are not liable for:</p>
            <ul>
              <li>Pre-existing damages or wear and tear on the vehicle.</li>
              <li>Valuables left inside the vehicle during service.</li>
              <li>Delays or issues caused by factors beyond our control, such as weather conditions.</li>
            </ul>

            <h2 id="customer-obligations">5. Customer Obligations</h2>
            <p>Customers must:</p>
            <ul>
              <li>Provide accurate booking information.</li>
              <li>Ensure access to water and power if opting for a mobile service.</li>
              <li>Make the vehicle available at the agreed time and location.</li>
            </ul>

            <h2 id="changes-to-terms">6. Changes to Terms</h2>
            <p>Gloss Wise reserves the right to update or modify these terms at any time. We will notify customers of any significant changes by updating the terms on our website.</p>

            <h2 id="governing-law">7. Governing Law</h2>
            <p>These terms and conditions are governed by the laws of New South Wales, Australia. Any disputes arising from the use of our services will be subject to the jurisdiction of the courts in New South Wales.</p>

            <h2 id="contact-us">8. Contact Us</h2>
            <p>If you have any questions about these Terms and Conditions, please contact us at <strong>zackary@glosswise.com.au</strong>.</p>
          </main>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
