// src/components/Footer.js
import React from 'react';
import './Footer.css';
import logo from '../assets/images/logo.png'; // Main logo image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer" aria-label="Gloss Wise Footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img
            src={logo}
            alt="Gloss Wise logo"
            className="logo"
            width="100"
            height="100"
            loading="lazy" // Enable lazy loading
          />
        </div>
        <p>© 2024 Gloss Wise. All Rights Reserved.</p>
        
        <div className="social-links">
          <a 
            href="https://www.facebook.com/people/Gloss-Wise-Detailing/61567259901289/"
            aria-label="Visit our Facebook page" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a 
            href="https://www.instagram.com/gloss_wise_detailing/" 
            aria-label="Visit our Instagram page" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>

        {/* Legal Links */}
        <div className="legal-links">
          <a href="/terms-and-conditions" aria-label="View our Terms and Conditions">Terms & Conditions</a>
          <a href="/privacy-policy" aria-label="View our Privacy Policy">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
